<template>
  <div class="authentication" :style="{'minHeight':cHeight}">
    <div class="main">
      <div class="top"><img src="../assets/icon_info.png" alt=""><span>尊敬的客户，为保证支付安全，请您在支付前先进行身份验证</span></div>
      <div class="bottom">
        <div class="row">客户信息</div>
        <div class="row">客户姓名<div>{{info.memberName}}</div></div>
        <div class="row">客户手机<div>{{info.memberMobile}}<input type="text" placeholder="请输入后四位" maxlength="4" onkeyup="value=value.replace(/[^\d]/g,'')" v-model="phone"></div></div>
        <div class="btn " :class="pass?'active':''" @click="checkpay">确定</div>
      </div>
    </div>
    <div class="foot">由无锡中铠信息咨询服务有限公司提供技术支持</div>
  </div>
</template>

<script>
import {
    GetOrderPayinfo,CheckPayinfo
  } from '@/api'
  export default {
    data() {
      return {
        cHeight: null,
        info:{},
        phone:'',
        pass:false
      }
    },
    watch:{
      phone(newVal){
        if(newVal.length==4){
          this.pass=true
        }else{
          this.pass=false
        }
      }
    },
    mounted() {
      this.cHeight = (document.documentElement.clientHeight || document.body.clientHeight) + 'px'
      document.title ='支付申请'
      console.log(this.$route)
      if(this.$route.query.id){
        localStorage.setItem('orderCustomerId',this.$route.query.id)
      }
      GetOrderPayinfo({
        "orderCustomerId": localStorage.getItem('orderCustomerId')
      }).then(res=>{
        if(res.state==0){
        this.info=res.data
        }else{
        this.$toast(res.data)
        }
      })
      if (this.isWeiXin() && !this.$route.query.wxOpenid&&!localStorage.getItem('wxOpenid')) {
        var url=window.location.href
        url = encodeURIComponent(url)
        console.log(url)
        localStorage.removeItem('wxOpenid')
        location.href = 'https://mob.ilvdo.com/Mobile/wxcode.html?wxto=' + url
      } else if (this.isWeiXin() && this.$route.query.wxOpenid) {
        localStorage.setItem('wxOpenid', this.$route.query.wxOpenid)
      }
      console.log(localStorage.getItem('wxOpenid'))
    },
    methods: {
     checkpay(){
      if(this.pass){
        CheckPayinfo({
          "orderCustomerId": localStorage.getItem('orderCustomerId'),
          "num": this.phone
        }).then(res=>{
          if(res.state==0){
            if(res.data.PaySate=='待支付'){
              if(res.data.payProof!=''){
              this.$router.push('pay_result?type=upload')
              }else{
                this.$router.push('pay_cashier')
              }
            }else if(res.data.PaySate=='已支付'){
              this.$router.push('pay_result?type=success')
            }else if(res.data.PaySate=='已取消'){
              this.$toast('支付已超时，订单已取消')
            }
          }else{
            this.$toast(res.data)
          }
        })
      }

     },
     isWeiXin() {
        var ua = window.navigator.userAgent.toLowerCase();
        if (ua.match(/MicroMessenger/i) == 'micromessenger') {
          return true;
        } else {
          return false;
        }
      },
    }
  }

</script>
<style scoped lang='scss'>
  .authentication{
    background: #F4F4F4 url('../assets/bg.png') no-repeat;
    background-size: 100% 500px;
    padding: 225px 30px 0;
    box-sizing: border-box;
    position: relative;
    .main{
      .top{
        background: url('../assets/bg_note.png') no-repeat;
        background-size: 100% 100%;
        width: 100%;
        height: 126px;
        text-align: center;
        font-size: 22px;color: #666666;
        padding-top: 22px;
        box-sizing: border-box;
        img{
          vertical-align: middle;
          width: 40px;
          height: 40px;
        }
        span{
        vertical-align: middle;
        }
      }
      .bottom{
        padding: 0 3px;
        background: #fff;
        border-radius: 0 0 20px 20px;
        padding: 0 35px 95px;
        width: 100%;
        box-sizing: border-box;
        .row{
          font-size: 28px;color: #999999;
          margin-bottom: 67px;
          >div{
            display: inline-block;
            margin-left: 50px;
            color: #666666;
            input{
              border: 0;
              width: 300px;
            }
          }
        }
        .row:nth-child(2){
          margin-bottom:35px;
        }
        .row:nth-child(3){
          margin-bottom: 0;
          padding: 50px 0 40px;
          border-top: 1px solid #F4F4F4;
          border-bottom: 1px solid #F4F4F4;
          
        }
        .btn{
          height: 80px;
          background: #B9D2EC;
          border-radius: 40px;
          margin-top: 100px;
          font-size: 36px;
          color: #FFFFFF;
          text-align: center;
          line-height: 80px;
        }
        .active{
          background: #1387FF;
        }
      }
    }
    .foot{
      width: 100%;
      text-align: center;
      font-size: 22px;color: #999999;
      position: absolute;
      bottom: 36px;
      left: 0;
    }
  }

</style>
